/**
 * Swiper Touch Slider
 * @type {}
 */

import Swiper from 'swiper';
import SwiperCore, { Autoplay , Navigation ,Pagination} from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

 
SwiperCore.use([Autoplay]); 
Swiper.use([Navigation]);
Swiper.use([Pagination]);


var swiper = new Swiper('.swiper-container', {
    slidesPerView: 1,
    spaceBetween: 5,
    mousewheel: false,
    loop:true,
    speed:500,
    autoplay: {
    	delay: 3000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    }
});  

var video_swiper = new Swiper('.video_swiper-container', {
    slidesPerView: 1,
    spaceBetween: 20,
    mousewheel: true,
    loop: true,
    speed:300,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
    },
    
});  




/**
     * Toggle between old and new Tour Dates
     * @type {}
 */
let toggleTab = () => {
    let old_dates = document.getElementById('old_dates');
    let arrow = document.getElementById('dates_arrow');
    
    if(old_dates.classList.contains('active')){
        old_dates.classList.remove("active");
        arrow.classList.remove("active");
        
    } else {
        old_dates.className += "active";
        arrow.classList.add('active');
    }
}

document.getElementById ("past_button").addEventListener ("click", toggleTab);
